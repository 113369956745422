var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-wrapper",attrs:{"id":"targetDiv"}},[_c('div',{staticClass:"modal-card"},[_c('div',{staticClass:"position-relative"},[_vm._m(0),_c('div',{staticClass:"video-notes-wrapper"},[_c('div',{staticClass:"video-notes",on:{"click":_vm.scrollDown}},[_vm._v(" 向下捲動 "),_c('b-icon',{attrs:{"icon":"chevron-double-down"}}),_vm._v(" 了解更多 ")],1)])]),_c('div',{staticClass:"modal-text p-4",attrs:{"id":"contentBelowVideo"}},[_c('div',{staticClass:"row justify-content-center align-items-center text-center"},[_vm._m(1),_c('div',{staticClass:"col-md"},[_vm._m(2),_c('div',{on:{"click":_vm.onClose}},[_c('Button',{staticClass:"mb-4",attrs:{"name":"立即啟用會籍","isHighlighted":"true"}})],1)])]),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"bg-black text-white p-4"},[_vm._m(6),_c('div',{staticClass:"row text-center"},[_vm._m(7),_c('div',{staticClass:"col-md-6"},[_c('div',{on:{"click":_vm.onClose}},[_c('Button',{staticClass:"mb-4",attrs:{"name":"立即啟用會籍","isHighlighted":"true"}})],1)])])])]),_c('div',{staticClass:"modal-close-button clickable",on:{"click":_vm.onClose}},[_c('b-icon',{attrs:{"icon":"x-lg"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('video',{attrs:{"id":"myVideo","width":"100%","height":"auto","controls":"","autoplay":"","playsinline":""}},[_c('source',{attrs:{"src":require("@/assets/mobile_1080x1920_v2.mp4"),"type":"video/mp4"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('img',{attrs:{"src":require("../assets/intro_logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('small',[_vm._v("NT$")]),_vm._v("1,200"),_c('small',[_vm._v("/年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-md-left text-center justify-content-center"},[_c('div',{staticClass:"col-md-4 col-6"},[_c('img',{attrs:{"src":require("../assets/intro_1.png")}})]),_c('div',{staticClass:"col-md col-12"},[_c('h3',[_vm._v("無通話次數限制")]),_c('p',[_vm._v("會員期間的通話次數無上限！只要您在車禍方面有任何的問題都歡迎您來找 PAMO！")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-md-left text-center justify-content-center"},[_c('div',{staticClass:"col-md-4 col-6"},[_c('img',{attrs:{"src":require("../assets/intro_2.png")}})]),_c('div',{staticClass:"col-md col-12"},[_c('h3',[_vm._v("不收取額外費用")]),_c('p',[_vm._v("如有需要延伸的律師法律服務我們一定會告知您，不會擅自收取額外費用！")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-md-left text-center justify-content-center"},[_c('div',{staticClass:"col-md-4 col-6"},[_c('img',{attrs:{"src":require("../assets/intro_3.png")}})]),_c('div',{staticClass:"col-md col-12"},[_c('h3',[_vm._v("每年贈1200 PAMO POINT")]),_c('p',[_vm._v(" 會員期間每年會有1200點法律服務折扣點數，會由與PAMO合作的法律事務所提供您服務。非線上的實體法律服務費為每小時4500元。1點等於一元，有效期間隨會期展延。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-4 text-md-left text-center justify-content-center"},[_c('div',{staticClass:"col-md-6 col-auto"},[_c('img',{attrs:{"src":require("../assets/intro_logo.png")}}),_c('h1',[_vm._v("會員獨享"),_c('br'),_vm._v("七大服務")])]),_c('div',{staticClass:"col-md-6 col-auto"},[_c('div',[_vm._v("即時車禍現場肇責分析")]),_c('div',[_vm._v("筆錄須知與提醒")]),_c('div',[_vm._v("協助處理車禍和解文件")]),_c('div',[_vm._v("合理車禍賠償分析")]),_c('div',[_vm._v("保險談判眉角提醒")]),_c('div',[_vm._v("車禍訴訟流程說明")]),_c('div',[_vm._v("安排實體律師諮詢")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h1',[_c('small',[_vm._v("NT$")]),_vm._v("1,200"),_c('small',[_vm._v("/年")])])])
}]

export { render, staticRenderFns }