<template>
  <div class="modal-container">
    <div class="modal-wrapper" id="targetDiv">
      <div class="modal-card">
        <div class="position-relative">
          <video id="myVideo" width="100%" height="auto" controls autoplay playsinline>
            <source src="@/assets/mobile_1080x1920_v2.mp4" type="video/mp4" />
          </video>
          <div class="video-notes-wrapper">
            <div @click="scrollDown" class="video-notes">
              向下捲動 <b-icon icon="chevron-double-down" /> 了解更多
            </div>
          </div>
        </div>
        <div id="contentBelowVideo" class="modal-text p-4">
          <div class="row justify-content-center align-items-center text-center">
            <div class="col-md-6"><img src="../assets/intro_logo.png" /></div>
            <div class="col-md">
              <h1><small>NT$</small>1,200<small>/年</small></h1>
              <div @click="onClose">
                <Button name="立即啟用會籍" isHighlighted="true" class="mb-4" />
              </div>
            </div>
          </div>
          <div class="row text-md-left text-center justify-content-center">
            <div class="col-md-4 col-6"><img src="../assets/intro_1.png" /></div>
            <div class="col-md col-12">
              <h3>無通話次數限制</h3>
              <p>會員期間的通話次數無上限！只要您在車禍方面有任何的問題都歡迎您來找 PAMO！</p>
            </div>
          </div>
          <div class="row text-md-left text-center justify-content-center">
            <div class="col-md-4 col-6"><img src="../assets/intro_2.png" /></div>
            <div class="col-md col-12">
              <h3>不收取額外費用</h3>
              <p>如有需要延伸的律師法律服務我們一定會告知您，不會擅自收取額外費用！</p>
            </div>
          </div>
          <div class="row text-md-left text-center justify-content-center">
            <div class="col-md-4 col-6"><img src="../assets/intro_3.png" /></div>
            <div class="col-md col-12">
              <h3>每年贈1200 PAMO POINT</h3>
              <p>
                會員期間每年會有1200點法律服務折扣點數，會由與PAMO合作的法律事務所提供您服務。非線上的實體法律服務費為每小時4500元。1點等於一元，有效期間隨會期展延。
              </p>
            </div>
          </div>
        </div>
        <div class="bg-black text-white p-4">
          <div class="row mb-4 text-md-left text-center justify-content-center">
            <div class="col-md-6 col-auto">
              <img src="../assets/intro_logo.png" />
              <h1>會員獨享<br />七大服務</h1>
            </div>
            <div class="col-md-6 col-auto">
              <div>即時車禍現場肇責分析</div>
              <div>筆錄須知與提醒</div>
              <div>協助處理車禍和解文件</div>
              <div>合理車禍賠償分析</div>
              <div>保險談判眉角提醒</div>
              <div>車禍訴訟流程說明</div>
              <div>安排實體律師諮詢</div>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-md-6">
              <h1><small>NT$</small>1,200<small>/年</small></h1>
            </div>
            <div class="col-md-6">
              <div @click="onClose">
                <Button name="立即啟用會籍" isHighlighted="true" class="mb-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-close-button clickable" @click="onClose"><b-icon icon="x-lg" /></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import 'swiper/css/swiper.css';
import Button from '@/elements/Button.vue';

export default {
  props: ['onClose'],
  components: {
    Button,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    ...mapState({
      profile: (state) => state.auth.profile,
    }),
  },
  mounted() {
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    const video = document.getElementById('myVideo');
    video.addEventListener('ended', this.scrollDown);
  },
  destroyed() {
    document.body.style.position = '';
    document.body.style.width = '';
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
        },
      },
    };
  },
  methods: {
    scrollDown() {
      const targetDiv = document.getElementById('targetDiv');
      const contentDiv = document.getElementById('contentBelowVideo');
      targetDiv.scrollTo({
        top: contentDiv.offsetTop,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';

.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.742);
  color: @dark-grey;
}

.modal-wrapper {
  height: 100%;
  overflow: auto;
  padding: 15px;
  position: relative;
  scroll-behavior: smooth;
}

.modal-card {
  max-width: 500px;
  width: 100%;
  min-height: 100%;
  background-color: white;
  border-radius: 20px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.user-status {
  background-color: black;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.modal-close-button {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.352);
  border: 2px solid grey;
  border-radius: 50%;
}

small {
  font-size: 0.7em;
}

.bg-black {
  background-color: black;
}
.text-white {
  color: white;
}
.video-notes-wrapper {
  position: absolute;
  width: 100%;
  bottom: 50px;
  z-index: 1;
  text-align: center;
}
.video-notes {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: bold;
  box-shadow: 0 0 10px black;
  opacity: 0.7;
}
</style>
