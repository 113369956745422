export default {
  methods: {
    userLogin(payload) {
      this.$store.dispatch('USER_LOGIN', payload);
    },
    verifyMobile(payload) {
      this.$store.dispatch('VERIFY_MOBILE', payload);
    },
    updateUser(payload) {
      this.$store.dispatch('UPDATE_USER', payload);
    },
    requestLogout() {
      this.$store.dispatch('REQUEST_LOGOUT', { isTokenExpired: false });
    },
    partnerAuth(payload) {
      this.$store.dispatch('PARTNER_AUTH', payload);
    },
  },
};
