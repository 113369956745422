export default {
  methods: {
    fetchContracts() {
      this.$store.dispatch('FETCH_CONTRACTS', {});
    },
    createContract(payload) {
      this.$store.dispatch('CREATE_CONTRACT', payload);
    },
    sendContractToOpposite(payload) {
      this.$store.dispatch('SEND_CONTRACT_TO_OPPOSITE', payload);
    },
    verifyContract(payload) {
      this.$store.dispatch('VERIFY_CONTRACT', payload);
    },
  },
};
