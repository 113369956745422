export default {
  methods: {
    fetchUserPlan() {
      this.$store.dispatch('FETCH_USER_PLAN', {});
    },
    addPlan(payload) {
      this.$store.dispatch('ADD_PLAN', payload);
    },
  },
};
