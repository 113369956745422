export default {
  methods: {
    fetchUserPayment() {
      this.$store.dispatch('FETCH_USER_PAYMENT', {});
    },
    fetchUserLicense() {
      this.$store.dispatch('FETCH_USER_LICENSE', {});
    },
    addUserPayment(payload) {
      this.$store.dispatch('ADD_USER_PAYMENT', payload);
    },
    bindUserPayment(payload) {
      this.$store.dispatch('BIND_USER_PAYMENT', payload);
    },
    bindUserCard(payload) {
      this.$store.dispatch('BIND_USER_CARD', payload);
    },
    deleteUserPayment(payload) {
      this.$store.dispatch('DELETE_USER_PAYMENT', payload);
    },
    orderPlan(payload) {
      this.$store.dispatch('ORDER_PLAN', payload);
    },
    checkCoupon(payload) {
      this.$store.dispatch('CHECK_COUPON', payload);
    },
    checkCouponDiscount(payload) {
      this.$store.dispatch('CHECK_COUPON_DISCOUNT', payload);
    },
  },
};
