export default {
  methods: {
    fetchRecords() {
      this.$store.dispatch('FETCH_RECORDS', {});
    },
    fetchRecordById({ id }) {
      this.$store.dispatch('FETCH_RECORD_BY_ID', { id });
    },
    fetchCityAndArea() {
      this.$store.dispatch('FETCH_CITY_AND_AREA', {});
    },
    fetchAreaByCity({ cityId, onSuccess }) {
      this.$store.dispatch('FETCH_AREA_BY_CITY', { cityId, onSuccess });
    },
    createRecord(payload) {
      this.$store.dispatch('CREATE_RECORD', payload);
    },
    getAddressByLatLng(payload) {
      this.$store.dispatch('GET_ADDRESS_BY_LATLNG', payload);
    },
  },
};
